RedactorX.lang['nl'] = {
    "accessibility": {
        "help-label": "Rich text editor"
    },
    "placeholders": {
        "figcaption": "Type bijschrift (optioneel)"
    },
    "popup": {
        "back": "Terug",
        "link": "Link",
        "add": "Toevoegen",
        "image": "Afbeelding",
        "add-image": "Afbeelding toevoegen"
    },
    "shortcuts": {
        "meta-a": "Alles selecteren",
        "meta-z": "Ongedaan maken",
        "meta-shift-z": "Opnieuw doen",
        "meta-shift-m": "Inline formaat verwijderen",
        "meta-b": "Vetgedrukt",
        "meta-i": "Cursief",
        "meta-u": "Onderstrepen",
        "meta-h": "Superscript",
        "meta-l": "Subscript",
        "meta-k": "Link",
        "meta-alt-0": "Normale tekst",
        "meta-alt-1": "Kop 1",
        "meta-alt-2": "Kop 2",
        "meta-alt-3": "Kop 3",
        "meta-alt-4": "Kop 4",
        "meta-alt-5": "Kop 5",
        "meta-alt-6": "Kop 6",
        "meta-shift-7": "Geordende lijst",
        "meta-shift-8": "Ongeordende lijst",
        "meta-indent": "Indent",
        "meta-outdent": "Outdent",
        "meta-shift-backspace": "Verwijderen",
        "meta-shift-o": "Toevoegen",
        "meta-shift-d": "Dupliceren",
        "meta-shift-up": "Omhoog",
        "meta-shift-down": "Naar beneden"
    },
    "buttons": {
        "add": "Add",
        "html": "HTML",
        "format": "Format",
        "bold": "Vet",
        "italic": "Cursief",
        "deleted": "Doorstreept",
        "link": "Link",
        "list": "Lijst",
        "image": "Afbeelding",
        "indent": "Inspringen",
        "outdent": "Uitspringen",
        "embed": "Embed",
        "table": "Tabel",
        "insert": "Invoegen",
        "save": "Opslaan",
        "cancel": "Annuleren",
        "delete": "Verwijderen",
        "duplicate": "Dupliceren",
        "shortcut": "Snelkoppelingen",
        "underline": "Onderstrepen",
        "undo": "Ongedaan maken",
        "redo": "Opnieuw",
        "code": "Code",
        "mark": "Markeren",
        "subscript": "Subscript",
        "superscript": "Superscript",
        "kbd": "Snelkoppeling",
        "image-settings": "Afbeeldings instellingen",
        "column-settings": "Kolominstellingen"
    },
    "blocks": {
        "text": "Tekst",
        "paragraph": "Alinea",
        "image": "Afbeelding",
        "embed": "Embed",
        "line": "Lijn",
        "table": "Tabel",
        "quote": "Citaat",
        "pre": "Code",
        "address": "Adres"
    },
    "format": {
        "p": "Normale tekst",
        "h1": "Kop 1",
        "h2": "Kop 2",
        "h3": "Kop 3",
        "h4": "Kop 4",
        "h5": "Kop 5",
        "h6": "Kop 6",
        "address": "Adres",
        "ul": "Ongeordende lijst",
        "ol": "Geordende lijst",
        "dl": "Definitielijst"
    },
    "embed": {
        "embed": "Embed",
        "caption": "Onderschrift",
        "description": "Plak een embed-/html-code of voer de url in (alleen vimeo of youtube-video)",
        "responsive-video": "Responsieve video"
    },
    "image": {
        "or": "or",
        "alt-text": "Alt-tekst",
        "link": "Link",
        "width": "Breedte",
        "caption": "Onderschrift",
        "link-in-new-tab": "Open link in nieuw tabblad",
        "url-placeholder": "Plak URL van afbeelding...",
        "upload-new-placeholder": "Sleep om een nieuwe afbeelding te uploaden<br>of klik om te selecteren"
    },
    "link": {
        "link": "Link",
        "edit-link": "Edit Link",
        "unlink": "Unlink",
        "link-in-new-tab": "Open link in nieuw tabblad",
        "text": "Tekst",
        "url": "URL"
    },
    "table": {
        "width": "Breedte",
        "nowrap": "Nowrap",
        "column": "Kolom",
        "add-head": "Voeg kop toe",
        "remove-head": "Verwijder kop",
        "add-row-below": "Rij hieronder toevoegen",
        "add-row-above": "Rij hierboven toevoegen",
        "remove-row": "Verwijder rij",
        "add-column-after": "Kolom toevoegen na",
        "add-column-before": "Voeg kolom toe voor",
        "remove-column": "Verwijder kolom",
        "delete-table": "Verwijder tabel"
    },
    "fullscreen": {
        "full-screen-title": "Volledig scherm"
    }
};