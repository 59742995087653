Vue.asyncComponent('ak-page-jumper', {
    props: {
        pageJumper: {
            type: Object,
            required: true,
            default: {}
        }
    },
    methods: {
        goToPage(page) {
            this.$emit('changePage', page);
        },
        showPage() {
            return Object.keys(this.pageJumper).length
        }
    }
}, 'grid/layout/ak-page-jumper.html');