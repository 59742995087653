Vue.directive('on-click-outside', {
    inserted: function (el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            const clickIsInsideElement = (el == event.target || el.contains(event.target));
            const clickIsInsideVueChildren = (binding.arg !== 'noChild' && isTargetInChildComponent(vnode.context, event.target));

            // we always allow a click inside the confirm unsaved changes modal
            const clickInsideConfirmUnsavedChanges =  event.target.closest('[data-ak-confirm-unsaved-changes]');

            // The redactor dropdowns can trigger a click outside when we have a wysiwig inside an stacked window for instance.
            // This is behavior we don't want this is wy we added this extra check that can be activated by a modifier e.g. v-on-click-outside.preventRedactorDropdown="callback()"
            const clickIsInsideRedactor = binding.modifiers.preventRedactorDropdown && (event.target.closest('.rx-popup') || event.target.closest('.redactor-modal'));

            // here I check that click was outside the el and his children
            if (!(clickIsInsideElement || clickIsInsideVueChildren || clickIsInsideRedactor || clickInsideConfirmUnsavedChanges) && event) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('mouseup', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('mouseup', el.clickOutsideEvent)
    },
})

/**
 * We loop through the vue child tree to see if the clicks wasen't in any of these children
 * this is needed because a child component isen't always in the child dom of the current component (e.g. Modals, stackedwindows...)
 * @param component
 * @param target
 * @returns {boolean}
 */
function isTargetInChildComponent(component, target) {
    let targetInChildComponent = false;

    // check in the children of this component
    component.$children.forEach(childComponent => {

        //check if target is in vue child component
        if (childComponent.$el.contains(target)) {
            targetInChildComponent = true;
        }

        if(isTargetInChildComponent(childComponent, target)) {
            targetInChildComponent = true;
        }
    });

    return targetInChildComponent;
}