Vue.asyncComponent('ak-tooltip', {
    data() {
        return {
            persistentOpen: true,
            hover: false,
            scrollPosition: null,
            tooltipPosition: null,
        }
    },
    props: {
        body: {
            type: String,
        },
        position: {
            type: String,
            default: 'top'
        },
        disabled: {
            type: Boolean,
        },
        persistent: {
            type: Boolean,
        },
        hidden: {
            type: Boolean,
        }
    },
    computed: {
        tooltipPositionTop() {
            if (! this.tooltipPosition || ! this.scrollPosition) {
                return null;
            }

            return this.tooltipPosition.top - this.scrollPosition.y  + 'px';
        },
        tooltipPositionleft() {
            if (! this.tooltipPosition || ! this.scrollPosition) {
                return null;
            }
            return this.tooltipPosition.left - this.scrollPosition.x  + 'px';
        }
    },
    methods: {
        mouseEnter() {
            if (this.disabled){
                return;
            }

            this.scrollPosition = {
                x: window.scrollX,
                y: window.scrollY,
            };
            this.hover = true;
        },
        mouseLeave() {
            if (this.disabled){
                return;
            }

            this.hover = false;
        },
        close() {
            this.persistentOpen = false;
        },
        calculatePosition() {
            this.tooltipPosition = {
                top: this.$refs.tooltip.getBoundingClientRect().top,
                left: this.$refs.tooltip.getBoundingClientRect().left
            }
            this.disableTeleport = false;
        }
    },
    mounted: function() {
        // next tick isn't correct sometimes other components need to render before we can set the position
        // it will always be wet finger work e. huehuehue
        setTimeout(() => {
            this.calculatePosition();
            this.$root.$el.append(this.$refs.tooltip);
        }, 500);
    },
    beforeDestroy: function() {
        if(this.$refs.tooltip.parentNode) {
            this.$refs.tooltip.parentNode.removeChild(this.$refs.tooltip);
        }
    }
}, 'layout/ak-tooltip.html');