import FormElementMixin from '../mixins/FormElementMixin.js';
import { codemirror } from '../../../assets/vendor/vue-codemirror/dist/vue-codemirror';
import '../../../assets/vendor/codemirror/mode/javascript/javascript.js'
import '../../../assets/vendor/codemirror/mode/htmlmixed/htmlmixed.js'
import '../../../assets/vendor/codemirror/mode/css/css.js'
import '../../../assets/vendor/codemirror/addon/edit/closebrackets.js';
import '../../../assets/vendor/codemirror/addon/edit/matchbrackets.js';
import '../../../assets/vendor/codemirror/addon/edit/matchtags.js';
import '../../../assets/vendor/codemirror/addon/edit/closetag.js';


Vue.asyncComponent('ak-code', {
    components: {
        codemirror
    },
    mixins: [FormElementMixin],
    props: {
        rows: {
            type: Number|String,
            required: false,
            default: 15
        },
        language: {
            type: String,
            required: true,
        }
    },
    computed: {
        /**
         * Return the options for the codeMirror plugin
         * @returns {{mode: string, lineNumbers: boolean, autoCloseBrackets: boolean, matchTags: {bothTags: boolean}, theme: (string), readOnly: boolean, matchBrackets: boolean, autoCloseTags: boolean}}
         */
        cmOptions() {
            // @todo change theme for dark mode?
            return {
                lineNumbers: true,
                theme: this.darkModeActive ? 'material-ocean' : 'default',
                mode: this.language,
                readOnly: this.readOnly,
                lineWrapping: true,
                matchBrackets: true,
                autoCloseBrackets: true,
                autoCloseTags: true,
                matchTags: {
                    bothTags: true
                },
            }
        },
        /**
         * Check if we are in dark mode
         * @returns {boolean|null|watch.darkMode|*}
         */
        darkModeActive() {
            if (! this.$darkModeHandler) {
                return false;
            }

            return this.$darkModeHandler.darkMode;
        }
    },
    mounted() {
        // Set the height of codemirror
        this.$refs.input.codemirror.setSize(null, (this.rows * 24.4) + 'px');
    }
}, 'form/controls/ak-code.html');